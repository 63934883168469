.icon {
    background-color: #ECE5F8;
    width: 38px;
    height: 38px;
    border-radius: 50%;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 25px;
    line-height: 38px;
    color: #0A004A;
    text-align: center;
}