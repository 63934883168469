.faq-content{
    background-color: #E1E9F5;
    padding: 50px 150px;
    h2{
        font-family: 'Poppins-Bold';
        font-style: normal;
        font-weight: 800;
        font-size: 30px;
        line-height: 45px;
        text-align: center;
        color: #0A004A;
        margin-bottom: 35px;
    }
    .faq-main-content{
        display: flex;
        flex-direction: column;
        gap: 35px;
        .MuiAccordion-root::before{
            display: none;
        }
        .MuiAccordion-root{
            padding: 10px 25px;
            box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.15);
            border-radius: 10px;
        }
        .MuiTypography-root{
            font-family: 'Poppins';
            font-style: normal;
            font-weight: 500;
            font-size: 20px;
            line-height: 30px;
            color: #0A004A;
        }
    }
}

@media screen and (max-width:992px) {
    .faq-content{
        padding: 50px;
    }
}

@media screen and (max-width:576px) {
    .faq-content{
        padding: 50px 0;
        .faq-main-content{
            .MuiAccordion-root{
                padding: 10px 15px;
            }
            .MuiAccordionSummary-root{
                padding: 0;
            }
        }
    }
}